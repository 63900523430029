import React from "react";
import EmployeeInfo from "./EmployeeInfo/employeeInfo";
import charlie from "./images/charlie.png";
import hoi from "./images/hoi.png";
import kane from "./images/kane.png";
import kharo from "./images/kharo.png";
import luke from "./images/luke.png";
import marco from "./images/marco.png";
import marta from "./images/marta.png";
import sophie from "./images/sophie.png";
import steve from "./images/steve.png";
import * as styles from "./employeesList.module.scss";
import CloseBigSvg from "../../../assets/svg/closeBig.svg";
import Modal from "../../Modal/Modal";
import cx from "classnames";
import { Trans } from "react-i18next";

const employeesList = [
  {
    name: "Charlie Kenny",
    photo: charlie,
    position: "aboutUs.team.kenny.position",
    fullDescription: "aboutUs.team.kenny.bio",
    question: "aboutUs.team.kenny.question",
    answer: "aboutUs.team.kenny.answer",
  },
  {
    name: "Luke Gardner",
    photo: luke,
    position: "aboutUs.team.gardner.position",
    fullDescription: "aboutUs.team.gardner.bio",
    question: "aboutUs.team.gardner.question",
    answer: "aboutUs.team.gardner.answer",
  },
  {
    name: "Kharo Anjorin",
    photo: kharo,
    position: "aboutUs.team.kharo.position",
    fullDescription: "aboutUs.team.kharo.bio",
    question: "aboutUs.team.kharo.question",
    answer: "aboutUs.team.kharo.answer",
  },
  {
    name: "Hoi Chan",
    photo: hoi,
    position: "aboutUs.team.hoi.position",
    fullDescription: "aboutUs.team.hoi.bio",
    question: "aboutUs.team.hoi.question",
    answer: "aboutUs.team.hoi.answer",
  },
  {
    name: "Marco Evangelisti",
    photo: marco,
    position: "aboutUs.team.marco.position",
    fullDescription: "aboutUs.team.marco.bio",
    question: "aboutUs.team.marco.question",
    answer: "aboutUs.team.marco.answer",
  },
  {
    name: "Jane Dobbie",
    photo: kane,
    position: "aboutUs.team.dobbie.position",
    fullDescription: "aboutUs.team.dobbie.bio",
    question: "aboutUs.team.dobbie.question",
    answer: "aboutUs.team.dobbie.answer",
  },
  {
    name: "Sophie Bean",
    photo: sophie,
    position: "aboutUs.team.sophie.position",
    fullDescription: "aboutUs.team.sophie.bio",
    question: "aboutUs.team.sophie.question",
    answer: "aboutUs.team.sophie.answer",
  },
  {
    name: "Marta Benetti",
    photo: marta,
    position: "aboutUs.team.marta.position",
    fullDescription: "aboutUs.team.marta.bio",
    question: "aboutUs.team.marta.question",
    answer: "aboutUs.team.marta.answer",
  },
  {
    name: "Steve Harland",
    photo: steve,
    position: "aboutUs.team.steve.position",
    fullDescription: "aboutUs.team.steve.bio",
    question: "aboutUs.team.steve.question",
    answer: "aboutUs.team.steve.answer",
  },
];

class EmployeesList extends React.Component {
  
  constructor(props) {
    super(props);
    this.state = {
      activeItemIndex: null,
      isPopupVisible: false,
    };

    this.closePopup = this.closePopup.bind(this);
  }

  setActiveItem(newActiveIndex, popup = false) {
    const { activeItemIndex } = this.state;
    this.setState({
      activeItemIndex:
        activeItemIndex !== newActiveIndex ? newActiveIndex : null,
      isPopupVisible: popup,
    });
  }

  closePopup() {
    this.setState({
      activeItemIndex: null,
      isPopupVisible: false,
    });
  }

  getEmployeesList(mobile = false) {
    const { activeItemIndex } = this.state;
    return employeesList.map((item, index) => {
      return (
        <EmployeeInfo
          key={index}
          name={item.name}
          position={item.position}
          photo={item.photo}
          fullDescription={item.fullDescription}
          question={item.question}
          answer={item.answer}
          active={activeItemIndex === index}
          action={() => this.setActiveItem(index, !mobile)}
        />
      );
    });
  }

  render() {
    const { activeItemIndex, isPopupVisible } = this.state;

    return (
      <>
        <div className={cx([styles.employeeList, styles.employeeList_mobile])}>
          {this.getEmployeesList(true)}
        </div>
        <div className={cx([styles.employeeList, styles.employeeList_desktop])}>
          {this.getEmployeesList()}
        </div>
        <Modal isOpen={isPopupVisible} onClose={this.closePopup}>
          {activeItemIndex !== null && (
            <div className={styles.popupContent}>
              <div className={styles.photoWrapper}>
                {employeesList[activeItemIndex].photo && (
                  <img
                    src={employeesList[activeItemIndex].photo}
                    alt=""
                    className={styles.photo}
                  />
                )}
              </div>
              <div className={styles.textWrapper}>
                <p className={styles.name}>
                  {employeesList[activeItemIndex].name}
                </p>
                <p className={styles.position}>
                  <Trans>{employeesList[activeItemIndex].position}</Trans>
                </p>
              </div>
              <p className={styles.fullDescription}>
                <Trans>{employeesList[activeItemIndex].fullDescription}</Trans>
              </p>
              <p className={styles.fullDescription}>
                <b>Q: </b><i><Trans>{employeesList[activeItemIndex].question}</Trans></i>
              </p>
              <p className={styles.fullDescription}>
                <b>A: </b><Trans>{employeesList[activeItemIndex].answer}</Trans>
              </p>
              <div
                className={styles.close}
                onClick={this.closePopup}
                onKeyPress={this.closePopup}
                role="button"
                tabIndex={0}
              >
                <CloseBigSvg />
              </div>
            </div>
          )}
        </Modal>
      </>
    );
  }
}

export default EmployeesList;
