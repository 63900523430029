import React from "react";
import * as styles from "./videoCall.module.scss";
import callPhoto from "./team.jpg";
import ScrollAnimation from "react-animate-on-scroll";

const VideoCall = () => (
  <div className={styles.videoCallWrapper}>
    <ScrollAnimation
      animateIn="fadeInRight"
      animateOnce
      style={{ zIndex: 2, position: "relative" }}
    >
      <img src={callPhoto} className={styles.photo} alt="Salve team" />
    </ScrollAnimation>
    <div className={styles.decoGreen} />
    <div className={styles.decoRed} />
    <div className={styles.decoPurple} />
  </div>
);

export default VideoCall;
