import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import SEO from "../components/seo";
import PageHeader from "../components/PageHeader/pageHeader";
import TwoColumnsSection from "../components/TwoColumnsSection/twoColumnsSection";
import EmployeesList from "../components/about/EmployeesList/employeesList";
import SectionTitle from "../components/SectionTitle/sectionTitle";
import VideoCall from "../components/about/VideoCall/videoCall";
import { useTranslation } from "react-i18next";

const AboutPage = () => {
  const { t } = useTranslation();
  return (
    <Layout menuColor={"black"}>
      <SEO title={t("menu.aboutUs")} />
      <TwoColumnsSection>
        <PageHeader title={t("aboutUs.header")} desc={t("aboutUs.subheader")} />
        <VideoCall />
      </TwoColumnsSection>
      <SectionTitle text={t("aboutUs.meetTheTeam")} />
      <EmployeesList />
    </Layout>
  );
};

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

export default AboutPage;
