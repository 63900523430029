// extracted by mini-css-extract-plugin
export var popupDescription = "employeesList-module--popupDescription--XYBnk";
export var active = "employeesList-module--active--2kC7a";
export var popupContent = "employeesList-module--popupContent--B-z01";
export var close = "employeesList-module--close--1pbHx";
export var photoWrapper = "employeesList-module--photoWrapper--26f9u";
export var photo = "employeesList-module--photo--2WnxQ";
export var textWrapper = "employeesList-module--textWrapper--3yKe1";
export var name = "employeesList-module--name--2Clfw";
export var position = "employeesList-module--position--stIGg";
export var fullDescription = "employeesList-module--fullDescription--SVxMp";
export var employeeList = "employeesList-module--employeeList--19cyR";
export var employeeList_mobile = "employeesList-module--employeeList_mobile--EgL0G";
export var employeeList_desktop = "employeesList-module--employeeList_desktop--3RECf";