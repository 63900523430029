import React, { PureComponent } from "react";
import Portal from "../Portal/Portal";
import ClickOutside from "../ClickOutside/ClickOutside";
import * as styles from "./Modal.module.scss";

class Modal extends PureComponent {
  componentDidMount = () => {
    const { isOpen } = this.props;
    if (isOpen) {
      document.addEventListener("keydown", this.onKeydown, false);
    }
  };

  componentDidUpdate(prevProps) {
    const { isOpen } = this.props;

    if (prevProps.isOpen === false && isOpen === true) {
      document.addEventListener("keydown", this.onKeydown, false);
      document.querySelector("html").style.overflow = "hidden";
    }

    if (prevProps.isOpen === true && isOpen === false) {
      document.removeEventListener("keydown", this.onKeydown, false);
      document.querySelector("html").style.overflow = "";
    }
  }

  componentWillUnmount = () => {
    const { isOpen } = this.props;
    if (isOpen) {
      document.removeEventListener("keydown", this.onKeydown, false);
    }
  };

  onKeydown = (event) => {
    if (event.keyCode === 27) {
      this.close();
    }
  };

  onClickOutside = () => {
    this.close();
  };

  close = () => {
    const { onClose } = this.props;
    onClose();
  };

  render() {
    const { isOpen, children } = this.props;
    return (
      <Portal isOpen={isOpen}>
        {isOpen && (
          <div className={styles.wrapper}>
            <ClickOutside active={isOpen} action={this.onClickOutside}>
              <div>{children}</div>
            </ClickOutside>
          </div>
        )}
      </Portal>
    );
  }
}

export default Modal;
