import * as styles from "./employeeInfo.module.scss";
import React from "react";
import { useTranslation } from "react-i18next";

const EmployeeInfo = ({
  name,
  position,
  photo,
  fullDescription,
  question,
  answer,
  active,
  action,
}) => {
  const { t } = useTranslation();
  return (
    <div
      className={styles.employeeInfoWrapper}
      onClick={action}
      onKeyPress={action}
      role="button"
      tabIndex={0}
    >
      <div className={styles.card}>
        <div className={styles.photoWrapper}>
          {photo && <img src={photo} alt="" className={styles.photo} />}
        </div>
        <div className={styles.textWrapper}>
          <p className={styles.name}>{name}</p>
          <p className={styles.position}>
            {t(position)}
          </p>
        </div>
        <div
          className={`${styles.fullDescription} ${active ? styles.active : ""}`}
        >
          <p>
            {t(fullDescription)}
          </p>
          <p>
            <b>Q: </b><i>{t(question)}</i>
          </p>
          <p>
            <b>A: </b>{t(answer)}
          </p>
        </div>
      </div>
    </div>
  );
};

export default EmployeeInfo;
