import React, { PureComponent } from "react";
import ReactDOM from "react-dom";

import * as styles from "./Portal.module.scss";

class Portal extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      canRender: false,
    };
  }

  componentDidMount() {
    this.modalRoot = document.getElementById("modal-root");
    if (!this.modalRoot) {
      const modalRoot = document.createElement("div");
      modalRoot.setAttribute("id", "modal-root");
      document.body.appendChild(modalRoot);
      this.modalRoot = modalRoot;
    }

    this.element = document.createElement("div");
    this.modalRoot.appendChild(this.element);
    this.setState({
      canRender: true,
    });
  }

  componentWillUnmount() {
    this.modalRoot.removeChild(this.element);
  }

  render() {
    const { children, isOpen } = this.props;
    const { canRender } = this.state;
    if (!canRender) {
      return null;
    }
    return ReactDOM.createPortal(
      <>
        {isOpen && (
          <div>
            <div className={styles.screenOverlay} />
          </div>
        )}
        {children}
      </>,
      this.element
    );
  }
}

export default Portal;
